import {Dictionary} from "./type";

export const iw: Dictionary = {
    mainAlert: 'כדי לא לאבד את תוצאות הבדיקה, נא להיכנס למערכת',
    loginButton: 'כניסה לחשבון',
    back: 'חזור',
    test: {
        purpose: 'מטרה:',
        description: 'תיאור:',
        startButton: 'התחל בדיקה',
        listenSoundButton: 'להקשיב',
        selectSoundButton: 'בחר',
        notFinishedTestText: 'הבדיקה כבר רצה אך לא הושלמה.',
        finishedTestText: 'המבחן הושלם!',
        continueTestButton: 'להמשיר',
        selectOtherTestButton: 'בחר מבחן אחר',
        repeatTestButton: 'להתחיל מחדש',
        repeatTestButton2: 'חזור על בדיקה זו',
        recommendsTitleText: 'אנו ממליצים להקשיב:',
    },
    loginForm: {
        wrongPasswordErrorText: 'שם משתמש או סיסמא שגויים',
        userAlreadyExistErrorText: 'משתמש זה כבר רשום',
        linkSentAlertText: 'דוא"ל נשלח לכתובת הדוא"ל שסיפקת כדי לאשר את הרישום שלך',
        recoveryLinkSentAlertText: 'דוא"ל נשלח לכתובת הדוא"ל שצוינה כדי לשחזר את הגישה',
        enter: 'להתחבר',
        registry: 'חרשמה',
        recoveryAccess: 'גישה לשחזור',
        password: 'סיסמה',
        cancel: 'ביטול',
        iAgree: 'אני מקבל את התנאים',
        agreementDocLinkName: 'הסכמת המשתמש',
        and: '',
        privacyDocLinkName: 'ומדיניות לגבי עיבוד נתונים אישיים',
    },
    profile: {
        profile: 'פרופיל',
        exit: 'התנתק',
        name: 'השם הפרטי',
        lastName: 'שם המשפחה',
        musicRelation: 'קשר למוזיקה',
        musicRelations: {
            notListen: 'I don\'t listen to music',
            likeToListen: 'I love listening to music',
            studiedMusic: 'I studied music, but I haven\'t been doing it for a long time',
            amateurMusician: 'Amateur musician',
            professionalMusician: 'Professional stage musician',
            academicMusician: 'Professional academic musician',
        },
        birthDate: 'תאריך לידה',
        country: 'מדינה',
        cancel: 'ביטול',
        save: 'לשמור'
    },
    restorePage: {
        passwordChangedSuccessfullyTitle: 'סיסמה שונתה בהצלחה!',
        passwordChangedSuccessfullyText: 'כדי להמשיך כמשתמש רשום, היכנס לאתר באמצעות כתובת הדוא"ל שלך וסיסמא',
        linkNotFoundTitle: 'הקישור לא נמצא!',
        linkNotFoundText: 'הקישור מיושן או לא קיים.',
        newPasswordForm: {
            title: 'הכנס סיסמא חדשה:',
            password: 'סיסמה',
            submit: 'שמור סיסמה חדשה',
        }
    }
}
