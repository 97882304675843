/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useRef, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useAudio = () => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const timelineRef = useRef<HTMLDivElement>(null);
    const handleRef = useRef<HTMLDivElement>(null);
    const [play, setPlay] = useState(false);
    const [{currentTime, duration}, setInfo] = useState({ currentTime: 0, duration: 0});
    const currentTimeRounded = Math.round(audioRef.current?.currentTime ?? 0);
    const currentRounded = Math.round(audioRef.current?.duration ?? 0);
    const timeupdate = useCallback(() => {
        if(!audioRef.current) return null;

        const {currentTime, duration}: any = audioRef.current;
        setInfo({currentTime, duration});

        const ratio = currentTime / duration;
        const offsetWidth = timelineRef?.current?.offsetWidth ?? 0;
        const position = (offsetWidth * ratio);
        if(handleRef?.current?.style) {
            handleRef.current.style.width = `${position}px`;
        }
    }, [currentTimeRounded, currentRounded]);

    const ended = () => {
        if(audioRef?.current?.currentTime) {
            audioRef.current.currentTime = 0;
            setPlay(false);
        }
    };

    const start = () => {
        if(audioRef?.current) {
            if (play) {
                setPlay(false);
                audioRef.current.pause();
            } else {
                setPlay(true);
                audioRef.current.play();
            }
        }
    };

    const volumeChange: any = (e: any, newValue: number) => {
        if(!audioRef?.current) {
            return;
        }
        audioRef.current.volume = newValue / 100;
    };

    const timelineMouseMove = (e: any) => {
        if(timelineRef.current && audioRef.current){
            const position = e.pageX - timelineRef.current.getBoundingClientRect().left;
            audioRef.current.currentTime = (position / timelineRef.current.offsetWidth)
                * audioRef.current.duration;
        }
    };

    useEffect(() => {
        timeupdate();
        if(audioRef?.current) {
            audioRef.current.addEventListener('timeupdate', timeupdate);
            audioRef.current.addEventListener('ended', ended);
        }
    }, [timeupdate]);

    return  {audioRef, timelineRef,handleRef, duration,currentTime, start, play, timelineMouseMove, volumeChange}
};


export const useStyles = makeStyles(() =>
    createStyles({
        info: {
            width: '100%'
        },
        name: {
            padding: '5px',
        },
        player: {
            display: 'flex',
            position: 'relative',
        },
        time: {
            display: 'none',
            fontSize: '15px',
            alignItems: 'center',
            wordBreak: 'normal',
            position: 'absolute',
            right: '35px',
            top: '7px',
        },
        volume: {
            display: 'none',
            padding: '5px',
            '&:hover': {
                range: {
                    display: 'flex',
                }
            }
        },

        timeline: {
            background: 'rgba(196, 196, 217, .2)',
            opacity: 0.8,
            borderRadius: '3px',
            height: '8px',
            width: '100%',
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
            }
        },

        handle: {
            width: 0,
            height: '100%',
            borderRadius: '3px',
            marginTop: '1px',
            background: '#007aff',
        },

        audio: {
            display: 'none',
        },

        button: {
            outline: 'none',
            background: 'none'
        },
        range: {
            display: 'none',
            position: 'absolute',
            width: '200px',
            top: 0,
            right: 0,
            borderRadius: '5px',
            background: '#dadada',
            height: '40px',
        },
    }),
);
