import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {TestTypes_testTypes_list_tests, TestTypes_testTypes_list_tests_image_cacheUrls} from "../../queries";
import {Box, Grid, Paper, Popover, Typography} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useSizeType, SizeType} from "../../utils/sizeType";

const useStyles = makeStyles<Theme, {preview?: boolean}>((theme: Theme) =>
    ({
        textItem: {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                overflow: 'hidden',
                maxHeight: ({preview}) => preview ? 'auto' : 160
            },
            [theme.breakpoints.down('xl')]: {
                paddingRight: ({preview}) => preview ? 10 : undefined
            }
        },
        title: {
            [theme.breakpoints.between("sm", 'xl')]: {
                minHeight: ({preview}) => preview ? 80 : 60
            },
            [theme.breakpoints.up("md")]: {
                display: ({preview}) => preview ? undefined : '-webkit-box !important',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical',
                whiteSpace: 'normal',
                overflow: 'hidden',
            }
        },
        textHider: {
            [theme.breakpoints.up("md")]: {
                background: ({preview}) => preview ? 'none' : `linear-gradient(1deg, white, transparent)`,
            }
        },
        link: {
            display: "block",
            margin: 'auto',
            width: '100%',
            textAlign: 'start',
        },
        paper: {
            margin: 'auto',
            width: '100%',
            textAlign: 'start',
            "&:hover": {
                backgroundColor: '#f2f9ff',
                '& $textHider': {
                    [theme.breakpoints.up("md")]: {
                        background: ({preview}) => preview ? 'none' : `linear-gradient(1deg, #f2f9ff, transparent)`,
                    },
                },
            },
        },
        image: {
            [theme.breakpoints.down('xl')]: {
                width: ({preview}) => preview ? 75 : 50,
                height: ({preview}) => preview ? 75 : 50,
            },
            [theme.breakpoints.up('md')]: {
                width: ({preview}) => preview ? 170 : 150,
                height: ({preview}) => preview ? 170 : 150,
            }
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: '5px',
        },
    }),
);

const getImageSrc = (urls?: TestTypes_testTypes_list_tests_image_cacheUrls, sizeType?: SizeType) => {
    return sizeType === SizeType.SMALL
        ? (urls?.small ?? '50x50')
        : (urls?.normal ?? '200x200');
};

function TestBox({sizeType, test: {details, id, image}, preview}: {
    sizeType: SizeType,
    test: TestTypes_testTypes_list_tests,
    preview?: boolean,
}) {

    const classes = useStyles({preview});

    return <Link to={`/test/${id}`} style={{textDecoration: "none"}} className={classes.link}>
        <Box sx={{flexGrow: 1}}>
            <Paper className={classes.paper}>
                <Box p={2} width="100">
                    <Grid
                        container
                        spacing={2}
                        wrap="nowrap"
                        justifyContent="flex-start"
                    >
                        <Grid item>
                            <div className={classes.image}>
                                <img
                                    className={classes.img}
                                    src={getImageSrc(image?.cacheUrls ?? undefined, sizeType)}
                                    alt={"logo"}
                                />
                            </div>
                        </Grid>
                        <Grid item xs zeroMinWidth className={classes.textItem}>
                            <Typography
                                gutterBottom
                                className={classes.title}
                                variant={sizeType === SizeType.SMALL ? "subtitle1" : "h6"}
                            >
                                {details?.[0]?.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary"
                                        noWrap={!preview && (sizeType === SizeType.SMALL)}>
                                {details?.[0]?.description}
                            </Typography>
                            <Box
                                position='absolute'
                                bottom={0}
                                width='100%'
                                height='1.5em'
                                className={classes.textHider}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    </Link>;
}

const transitionDuration = {
    appear: 500,
    enter: 300,
    exit: 0,
}

export function TestListItem({
    test
}: {
    test: TestTypes_testTypes_list_tests | null,
}) {
    const [anchorRect, setAnchorRect] = React.useState<DOMRect | null>(null);

    let timoutId = useRef<NodeJS.Timeout>();

    useEffect(() => handlePopoverCancel, []);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();

        timoutId.current = setTimeout(() => {
            setAnchorRect(rect)
        }, 1000);
    };

    const handlePopoverCancel = () => {
        clearTimeout(timoutId.current);
    };

    const handlePopoverClose = () => {
        setAnchorRect(null);
    };

    const open = Boolean(anchorRect);
    const {left = 0, top = 0, height = 0, width = 0} = anchorRect ?? {};

    const sizeType = useSizeType();

    if(!test) {
        return null;
    }

    return (
        <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverCancel}>
            <TestBox sizeType={sizeType} test={test}/>
            <Popover
                open={open}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                onBackdropClick={handlePopoverClose}
                transitionDuration={transitionDuration}
                anchorReference="anchorPosition"
                anchorPosition={{left: left + width / 2,top: top + height / 2 }}
            >
                <Box minWidth={width + 20} maxWidth={width * 1.7} minHeight={height + 20} onMouseLeave={handlePopoverClose}>
                    <TestBox sizeType={sizeType} test={test} preview/>
                </Box>
            </Popover>
        </Box>
    );
}
