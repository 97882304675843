import {Token} from "../queries";

class LocalStorage {
    set token(token: Token | null) {
        if(!token){
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    get token(): Token | null {
        try {
            return JSON.parse(localStorage.getItem('token') || '') as Token || null
        } catch (e) {
            return null
        }
    }

    set userHash(hash: string | null) {
        if(!hash){
            localStorage.removeItem('userHash');
        } else {
            localStorage.setItem('userHash', JSON.stringify(hash));
        }
    }

    get userHash(): string | null {
        try {
            return JSON.parse(localStorage.getItem('userHash') || '') as string || null
        } catch (e) {
            return null
        }
    }
}

export const storage = new LocalStorage();
