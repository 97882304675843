import {v4 as uuid_v4} from 'uuid';
import { storage } from './localStorage';

const getUserHash = () => {
    let hash = storage.userHash;
    if (!hash) {
        hash = uuid_v4();
        storage.userHash = hash;
    }
    return hash;
};

const isAuth = () => {
    return !!storage.token
};

export const Query = {
    userHash: getUserHash,
    isAuth
};

export const Mutation = {
    userHash: getUserHash,
    isAuth,
    token: () => storage.token
};
