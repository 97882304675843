import {gql} from "@apollo/client";

export const GET_TEST_TYPES = gql`
    query TestTypes($lang: String = "ru") {
        testTypes {
            header {
                totalCount
            }
            list {
                id
                details(lang: $lang) {
                    id
                    title
                }
                tests {
                    id
                    details(lang: $lang) {
                        id
                        title
                        description
                    }
                    image {
                        cacheUrls {
                            small
                            normal
                        }
                    }
                }
            }
        }
    }`;
