import {gql} from "@apollo/client";

export const USER_INFO_FRAGMENT = gql`
    fragment UserInfoFragment on User {
        id
        email
        firstName
        lastName
        musicRelation
        data {
            birthday
            country
        }
    }
`;

export const GET_PROFILE = gql`
    query GetProfile {
        user {
            ...UserInfoFragment
        }
        countries {
            id
            name
        }
    }
    ${USER_INFO_FRAGMENT}
`;

export const SAVE_PROFILE = gql`
    mutation SaveProfile($input: UserInput!) {
        updateUser(input: $input) {
            ...UserInfoFragment
        }
    }
    ${USER_INFO_FRAGMENT}
`;

export const LOGOUT = gql`
    mutation Logout ($token: String = "") {
        token @client(always: true){
            refreshToken {
                token @export(as: "token")
            }
        }
        logout(token: $token)
    }
`;
