import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {gql} from "@apollo/client";

export const useStyles = makeStyles(() =>
    createStyles({
        loginTabs: {
            boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)'
        }
    }),
);

export const TOKEN_FRAGMENT = gql`
    fragment Token on LoginResponse {
        accessToken
        refreshToken {
            token
            expiresAt
        }
    }
`;

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        loginWithEmail(email: $email, password: $password){
            ...Token
        }
    }
    ${TOKEN_FRAGMENT}
`;

export const REGISTRATION = gql`
    mutation Registration($email: String!, $password: String!) {
        registerWithEmail(email: $email, password:$password ) {
            id
            data {
                birthday
                country
            }
            email
            firstName
            lastName
            patronymic
            phone
        }
    }
`;

export const SEND_LINK_FOR_RESTORE_ACCESS = gql`
    mutation SendLinkForRestoreAccess($email: String!) {
        sendLinkForRestoreAccess(email: $email )
    }
`;

export const SAVE_USER_HASH = gql`
    mutation SaveUserHash($userHash: String = "") {
        userHash @client(always: true) @export(as: "userHash")
        saveUserHash(userHash: $userHash)
    }
`;
