import {useTheme} from "@mui/material";
import {useCallback, useEffect, useState} from "react";

export enum SizeType {
    SMALL = 'small',
    NORMAL = 'normal',
}

export const useSizeType = (breakPoint?: number) => {
    const theme = useTheme();
    const mdBreakPoint = breakPoint ?? theme.breakpoints.values.md;

    const getSizeType = useCallback(() => {
        if (document.body.scrollWidth < mdBreakPoint) {
            return SizeType.SMALL
        }
        return SizeType.NORMAL
    },[mdBreakPoint]);

    const [sizeType, setSizeType] = useState(getSizeType());
    const changeSizeType = useCallback(() => {
        setSizeType(getSizeType());
    }, [getSizeType]);

    useEffect(() => {
        window.addEventListener('resize', changeSizeType);
        return () => {
            window.removeEventListener('resize', changeSizeType);
        }
    }, [changeSizeType]);

    return sizeType;
}
