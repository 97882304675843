import {GetTestById_tests_list_details} from "../../queries";
import {useTranslation} from "react-i18next";
import {Box, Skeleton, Typography} from "@mui/material";
import React from "react";

type TestDetailsProps = {
    details: GetTestById_tests_list_details | null,
    loading: boolean,
};

export function TestDetails({details, loading}: TestDetailsProps) {
    const {t} = useTranslation();
    return <>
        {(!!details?.mission || loading) && (
            <Box mb={2} key={'mission'}>
                <Typography variant="subtitle1">{loading? <Skeleton width={200} /> : t('test.purpose')}</Typography>
                <Typography variant="body1" color="textSecondary">
                    {loading ? <Skeleton width='100%' /> : details?.mission}
                </Typography>
            </Box>
        )}
        {(!!details?.description || loading) && (
            <Box mb={2} key={'description'}>
                <Typography variant="subtitle1">{loading? <Skeleton width={200} /> : t('test.description')}</Typography>
                <Typography variant="body1" color="textSecondary">
                    {loading && (
                        Array(5).fill(true).map((_, key) => (
                           <Skeleton key={key} width='100%' />
                        ))
                    )}
                    {details?.description}
                </Typography>
            </Box>
        )}
    </>;
}
