import React, {useMemo} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Home} from './components/Home/Home';
import {TestList} from './components/TestList/TestList';
import {Test} from './components/Test/Test';
import {client} from "./core/client";
import {ApolloProvider} from '@apollo/client';
import {
    Box, Container,
} from '@mui/material';

import {Header} from "./components/Header/Header";
import {ConfirmPage} from "./components/ConfirmPage/ConfirmPage";
import {RestorePage} from "./components/RestorePage/RestorePage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './i18n';
import {useTranslation} from "react-i18next";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
function App() {
    const {i18n} = useTranslation();
    const direction = i18n.dir();
    document.dir = direction;

    const cache = useMemo(() => {
        if(direction==='rtl') {
            return createCache({
                key: 'muirtl',
                stylisPlugins: [prefixer, rtlPlugin],
            });
        }
        return createCache({
            key: 'mui'
        })
    }, [direction])

    const theme = createTheme({
        direction,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1740,
            },
        },
    });


    return (
        <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
            <Header/>
            <Box mt={5}>
                <Container maxWidth="xl">
                    <Switch>
                        <Route exact path="/"><TestList/></Route>
                        <Route path='/test/:id' exact><Test/></Route>
                        <Route path='/confirm/:token' exact><ConfirmPage/></Route>
                        <Route path='/restore/:token' exact><RestorePage/></Route>
                        <Route path='/tests' exact><TestList/></Route>
                        <Route><Home/></Route>
                    </Switch>
                </Container>
            </Box>
            <Box 
                p="20px" 
                textAlign="center" 
                fontSize="18px" 
                fontWeight={600} 
                color="#898989"
            >
                newmusictheory@gmail.com
            </Box>
        </ApolloProvider>
        </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
