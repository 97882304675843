import {gql} from "@apollo/client";

export const typeDefs = gql`
    directive @client(
        always: Boolean
    ) on FIELD
    
    directive @export(
        as: String
    ) on FIELD
    
    extend type Query {
        userHash: String
        isAuth: Boolean
    }
    extend type Mutation {
        userHash: String
        token: LoginResponse
        isAuth: Boolean
    }
`;
