import React, {useEffect, useRef, useState} from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import IconButton from '@mui/material/IconButton';
import Player from '../../elements/Player/Player';
import {useMutation} from "@apollo/client";
import {GET_RECOMMENDATIONS, SET_USER_TEST_EXCERPT} from "./Recommendations.const";
import {GetRecommendations, SetUserTestExcerpt, SetUserTestExcerptVariables} from "../../queries";
import {useTranslation} from "react-i18next";

export const Recommendations = ({ userTestId }: any) => {
  const [likeMap, setLikeMap] = useState<{[key: string]: boolean | null}>({});
  const [setUserTestExcerpt ] = useMutation<SetUserTestExcerpt, SetUserTestExcerptVariables>(SET_USER_TEST_EXCERPT);
  const [getRecommendations, { data }] = useMutation<GetRecommendations>(GET_RECOMMENDATIONS, {variables:{userTestId}});
  const {t} = useTranslation();
  useEffect(() => {
    if(userTestId){
      getRecommendations()
    }
  }, [userTestId, getRecommendations]);

  const recommendationsRef = useRef<HTMLDivElement>();

  const setRelation = (excerptId: string, like: boolean) => async() => {
    await setUserTestExcerpt({ variables: { userTestId, excerpt: excerptId, like } });
    setLikeMap({...likeMap, [excerptId]: like})
  };

  const hasRecommendations = (data?.recommendations?.length ?? 0) > 0;

  useEffect(() => {
    if(hasRecommendations) {
      recommendationsRef?.current?.scrollIntoView(true);
    }
  }, [hasRecommendations]);

  return (
    <div ref={recommendationsRef as any}>
      <div><b>{t('test.recommendsTitleText')}</b><br/><br/></div>
      {data?.recommendations?.map((excerpt) => {
        if(!excerpt?.id) return null;

        let filename = excerpt.name || 'Отрывок без названия';
        if (excerpt.part?.composition?.composer?.name) {
          filename = `${excerpt.part?.composition?.composer?.name} - ${filename}`
        }
        return (
          <div key={excerpt.id}>
            <Player
                path={excerpt.file?.path || ''}
                filename={filename}
            />
            <div>
              <IconButton size="large">
                <ThumbUpIcon
                    color={ likeMap[excerpt.id] ? "primary" : undefined }
                    onClick={ setRelation(excerpt.id ?? '', true) }
                />
              </IconButton>
              <IconButton size="large">
                <ThumbDownIcon
                    color={ likeMap[excerpt.id]===false ? "primary" : undefined }
                    onClick={ setRelation(excerpt.id ?? '', false) }
                />
              </IconButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};
