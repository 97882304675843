import {useMutation} from "@apollo/client";
import {CONFIRM_ACCESS} from "./ConfirmPage.const";
import {ConfirmAccess, ConfirmAccessVariables} from "../../queries";
import React, {useEffect} from "react";
import { useParams } from "react-router-dom";

export const ConfirmPage = () => {

    const {token} = useParams<{token: string}>();
    const [confirmAccess, {data, error}] = useMutation<ConfirmAccess, ConfirmAccessVariables>(CONFIRM_ACCESS);

    useEffect(() => {
        if(token) {
            confirmAccess({variables: {token}})
        }
    }, [confirmAccess, token]);

    if (data?.confirmAccess === true) {
        return (
            <>
                <div>Адрес успешно подтверждён!</div>
                <div>Для продолжения в качестве зарегистрированного пользователя войдите на сайт с использованием адреса
                    вашей электронной почты и пароля
                </div>
            </>
        )
    }

    if (data?.confirmAccess === false || error) {
        return (
            <>
                <div>Ссылка не найдена!</div>
                <div>Ссылка устарела, или не существует.</div>
            </>
        )
    }

    return <div>Проверка доступа...</div>
}
