import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import React from "react";

export function NotStartedTestActions(props: { startTest: () => void }) {
    const {t} = useTranslation();
    return <Box my={5}>
        <Grid container justifyContent="center">
            <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={props.startTest}
            >{t('test.startButton')}</Button>
        </Grid>
    </Box>;
}
