import { gql } from "@apollo/client";

export const GET_TEST_BY_ID = gql`
    query GetTestById($id: ID!, $userHash: ID = "", $isAuth: Boolean = false, $lang: String = "ru") {
        userHash @client(always: true) @export(as: "userHash")
        isAuth @client(always: true) @export(as: "isAuth")
        tests(input: {filter: {ids: [$id]}}) {
            header {
                totalCount
            }
            list {
                id
                testTypeId
                details(lang: $lang) {
                    id
                    title
                    description
                    mission
                }
                completionStatus(userHash: $userHash)
                image {
                    cacheUrls {
                        large
                        normal
                    }
                }
            }
        }
        checkAuth(isAuth: $isAuth)
    }
`;

export const NEW_STEP = gql`
    mutation NewStep($userHash: ID = "", $testId: ID!, $isNew: Boolean=false, $winner: ID=null, $looser:ID=null, $isAuth: Boolean = false) {
        userHash @client(always: true) @export(as: "userHash")
        isAuth @client(always: true) @export(as: "isAuth")
        checkAuth(isAuth: $isAuth)
        test(userHash: $userHash, testId: $testId, isNew: $isNew, winner: $winner, looser:$looser){
            userTestId
            sounds {
                id
                path
            }
            percent
            test {
                id
                completionStatus(userHash: $userHash)
            }
        }
    }
`;
