import {Dictionary} from "./type";

export const ru: Dictionary = {
    mainAlert: 'Чтобы не потерять результаты тестирования выполните вход в систему',
    loginButton: 'Вход',
    back: 'Назад',
    test: {
        purpose: 'Цель:',
        description: 'Описание:',
        startButton: 'Начать тестирование',
        listenSoundButton: 'слушать',
        selectSoundButton: 'выбрать',
        notFinishedTestText: 'Тест уже запускался, но не был завершен.',
        finishedTestText: 'Тест завершен!',
        continueTestButton: 'Продолжить',
        selectOtherTestButton: 'Выбрать другой тест',
        repeatTestButton: 'Начать с начала',
        repeatTestButton2: 'Повторить этот тест',
        recommendsTitleText: 'Рекомендуем послушать:',
    },
    loginForm: {
        wrongPasswordErrorText: 'Неверный логин или пароль',
        userAlreadyExistErrorText: 'Такой пользователь уже зарегистрирован',
        linkSentAlertText: 'На указанный адрес электронной почты отправлено письмо для подтверждения регистрации',
        recoveryLinkSentAlertText: 'На указаный адрес электронной почты отправлено письмо для восстановления доступа',
        enter: 'Вход',
        registry: 'Регистрация',
        recoveryAccess: 'Восстановление доступа',
        password: 'Пароль',
        cancel: 'Отмена',
        iAgree: 'Я принимаю условия',
        agreementDocLinkName: 'Пользовательского соглашения',
        and: 'и',
        privacyDocLinkName: 'Политики в отношении обработки персональных данных',
    },
    profile: {
        profile: 'Профиль',
        exit: 'Выход',
        name: 'Имя',
        lastName: 'Фамилия',
        musicRelation: 'Отношение к музыке',
        musicRelations: {
            notListen: 'Музыку не слушаю',
            likeToListen: 'Люблю слушать музыку',
            studiedMusic: 'Учился музыке, но давно не занимаюсь',
            amateurMusician: 'Музыкант-любитель',
            professionalMusician: 'Профессиональный эстрадный музыкант',
            academicMusician: 'Профессиональный академический музыкант',
        },
        birthDate: 'Дата рождения',
        country: 'Страна',
        cancel:'Отмена',
        save: 'Сохранить'
    },
    restorePage: {
        passwordChangedSuccessfullyTitle: 'Пароль успешно изменён!',
        passwordChangedSuccessfullyText: 'Для продолжения в качестве зарегистрированного пользователя войдите на сайт ' +
            'с использованием адреса вашей электронной почты и пароля',
        linkNotFoundTitle: 'Ссылка не найдена!',
        linkNotFoundText: 'Ссылка устарела, или не существует.',
        newPasswordForm: {
            title: 'Введите новый пароль:',
            password: 'Пароль',
            submit: 'Сохранить новый пароль',
        }
    }
}
