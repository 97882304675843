import React, {useCallback, useEffect, useState} from "react";
import {Box, IconButton, Skeleton, SkeletonProps, styled, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {Recommendations} from "../Recommendations/Recommendations";
import {useTest} from "./Test.hooks";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {GetTestById_tests_list_image_cacheUrls} from "../../queries";
import {useTranslation} from "react-i18next";
import {StepActions} from "./StepActions";
import {NotFinishedTestActions} from "./NotFinishedTestActions";
import {CompletedTestActions} from "./CompletedTestActions";
import {NotStartedTestActions} from "./NotStartedTestActions";
import {TestDetails} from "./TestDetails";
import {SizeType, useSizeType} from "../../utils/sizeType";

const InlineSkeleton = styled(Skeleton)<SkeletonProps>({
    display: 'inline-block'
})

export const Test = () => {
    const [completedNow, setCompletedNow] = useState<boolean | null>(null);

    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === 'rtl';
    const id = useParams<{ id: string }>().id;
    const {test, loading, stepLoading, currentStepSounds, percent, completed, continueTest, restartTest, nextStep, userTestId} = useTest(id ?? '');
    useEffect(() => {
        if (completed === true && completedNow === false) {
            setCompletedNow(true)
        }
        if (completed === false) {
            setCompletedNow(false);
        }
    }, [completed, completedNow]);


    const sizeType = useSizeType(500);

    const getImageSrc = useCallback((urls?: GetTestById_tests_list_image_cacheUrls) => {
        return sizeType === SizeType.NORMAL
            ? (urls?.large ?? '400x400')
            : (urls?.normal ?? '200x200');
    }, [sizeType]);
    const pictureSize = sizeType === SizeType.NORMAL ? 400 : 200;

    return <>
        <Box mb={2}>
            <IconButton color="primary" size="small" component={Link} to="/">
                {isRtl ? (
                    <ArrowForwardIosIcon fontSize="small"/>
                ) : (
                    <ArrowBackIosIcon fontSize="small"/>
                )}
                {t('back')}
            </IconButton>
        </Box>
        <Typography variant="h4">
            {loading ? <Skeleton width={pictureSize + 50} /> : test?.details?.[0]?.title}
        </Typography>
        {!(completedNow || currentStepSounds || stepLoading) && (
            <Typography align="center">
                {loading ? (
                    <InlineSkeleton variant='rounded' width={pictureSize} height={pictureSize} />
                ) : (
                    <img style={{borderRadius: 10}} alt="" src={getImageSrc(test?.image?.cacheUrls ?? undefined)}/>
                )}
            </Typography>
        )}
        {loading && (
            <Box my={5}>
                <Typography variant="h5" align="center">
                    <InlineSkeleton width={pictureSize + 80} />
                </Typography>
            </Box>
        )}
        {(currentStepSounds || stepLoading) ? (
            <StepActions percent={percent} loading={stepLoading} currentStepSounds={currentStepSounds} nextStep={nextStep}/>
        ) : ( completed === false && !stepLoading) && (
            <NotFinishedTestActions continueTest={continueTest} restartTest={restartTest}/>
        )}
        {currentStepSounds === null && completed === null && !stepLoading && !loading && (
            <NotStartedTestActions startTest={restartTest}/>
        )}
        {completed && (
            <CompletedTestActions restartTest={restartTest}/>
        )}
        {!completedNow && (
            <TestDetails loading={loading} details={test?.details?.[0] ?? null}/>
        )}
        {completedNow && userTestId && (
            <Box>
                <Recommendations userTestId={userTestId}/>
            </Box>
        )}
    </>;
};
