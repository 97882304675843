import {initReactI18next} from "react-i18next";
import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {en} from "./locales/en";
import {ru} from "./locales/ru";
import {de} from "./locales/de";
import {iw} from "./locales/iw";

export const resources = {
    en: {
        translation: en,
    },
    ru: {
        translation: ru,
    },
    de: {
        translation: de,
    },
    iw: {
        translation: iw,
    },
} as const;

const fallbackLng = ['en'];

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        load: 'currentOnly',
        supportedLngs: ['en', 'ru', 'de', 'iw'],
        resources,
    });

export default i18n;
