import React, {ReactElement} from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import {VolumeDown, VolumeUp} from '@mui/icons-material';
import Slider from '@mui/material/Slider';
import {useAudio, useStyles} from "./Player.hooks";

type PropTypes = {
    className?: string,
    path: string,
    filename: string | ReactElement
}

const Player = (props: PropTypes) => {
    const {path, filename, className} = props;
    const fullPath = (process.env.REACT_APP_API_ENDPOINT ?? '') + path.slice(1);
    const classes = useStyles();
    const {audioRef, timelineRef, handleRef, duration, currentTime, start, play, timelineMouseMove, volumeChange} = useAudio();

    return (
        <div className={`${classes.player} ${className}`}>
            <audio ref={audioRef}>
                <source src={`${fullPath}.mp3`} type='audio/mpeg'/>
                <source src={`${fullPath}.ogg`} type='audio/ogg; codecs=vorbis'/>
                Тег audio не поддерживается вашим браузером.
                <a href={`${fullPath}.mp3`}>Скачать</a>
                .
            </audio>
            <div>
                <IconButton onClick={start} size='small'>
                    {play ? <PauseCircleOutlineIcon fontSize='large'/> :
                        <PlayCircleOutlineIcon fontSize='large'/>}
                </IconButton>
            </div>
            <div className={classes.info}>
                <div className={classes.name}>{filename}</div>
                <div className={classes.timeline} ref={timelineRef} onClick={timelineMouseMove}>
                    <div className={classes.handle} ref={handleRef}/>
                </div>
            </div>
            {(!Number.isNaN(duration)) && (
                <div className={classes.time}>
                    {Math.round(currentTime * 100) / 100}
                    <span>/</span>
                    {Math.round(duration * 100) / 100}
                </div>
            )}
            <div className={classes.volume}>
                <IconButton size='small'><VolumeUp/></IconButton>
                <Grid container spacing={2} className={classes.range}>
                    <Grid item>
                        <VolumeDown/>
                    </Grid>
                    <Grid item xs>
                        <Slider defaultValue={100} onChange={volumeChange}
                                aria-labelledby='continuous-slider'/>
                    </Grid>
                    <Grid item>
                        <VolumeUp/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Player;
