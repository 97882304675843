import {gql} from "@apollo/client";

export const SET_USER_TEST_EXCERPT = gql`
    mutation SetUserTestExcerpt($userTestId: ID!, $excerpt: ID!, $like: Boolean!) {
        setUserTestExcerpt(userTestId: $userTestId, excerpt: $excerpt, like: $like)
    }
`;

export const GET_RECOMMENDATIONS = gql`
    mutation GetRecommendations($userTestId: ID!) {
        recommendations(userTestId: $userTestId) {
            id
            name
            part {
                id
                composition {
                    id
                    composer {
                        id
                        name
                    }
                }
            }
            file {
                encoding
                id
                mimetype
                path
            }
        }
    }
`;
