import {useMutation} from "@apollo/client";
import {RESTORE_ACCESS} from "./RestorePage.const";
import {RestoreAccess, RestoreAccessVariables} from "../../queries";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

export const RestorePage = () => {

    const {token = ''} = useParams<{ token: string }>();
    const [restoreAccess, {data, error}] = useMutation<RestoreAccess, RestoreAccessVariables>(RESTORE_ACCESS);
    const [password, setPassword] = useState('');
    const {t} = useTranslation();

    if (data?.restoreAccess === true) {
        return (
            <>
                <div>{t('restorePage.passwordChangedSuccessfullyTitle')}</div>
                <div>{t('restorePage.passwordChangedSuccessfullyText')}</div>
            </>
        )
    }

    if (data?.restoreAccess === false || error) {
        return (
            <>
                <div>{t('restorePage.linkNotFoundTitle')}</div>
                <div>{t('restorePage.linkNotFoundText')}</div>
            </>
        )
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        return restoreAccess({variables: {token, newPassword: password}});
    };

    return (
        <form onSubmit={onSubmit}>
            <div>{t('restorePage.newPasswordForm.title')}</div>
            <div>
                <TextField
                    margin="dense"
                    label={t('restorePage.newPasswordForm.password')}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />
            </div>
            <div>
                <Button type="submit" color="primary" disabled={!password}>
                    {t('restorePage.newPasswordForm.submit')}
                </Button>
            </div>
        </form>
    )
}
