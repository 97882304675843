import {Dictionary} from "./type";

export const en: Dictionary = {
    mainAlert: 'In order not to lose the test results, please log in to the system',
    loginButton: 'Log in',
    back: 'Back',
    test: {
        purpose: 'Purpose:',
        description: 'Description:',
        startButton: 'Start test',
        listenSoundButton: 'listen',
        selectSoundButton: 'select',
        notFinishedTestText: 'The test has already run but has not been completed.',
        finishedTestText: 'Test completed!',
        continueTestButton: 'Continue',
        selectOtherTestButton: 'Choose another test',
        repeatTestButton: 'Start over',
        repeatTestButton2: 'Repeat this test',
        recommendsTitleText: 'We recommend listening:',
    },
    loginForm: {
        wrongPasswordErrorText: 'Wrong login or password',
        userAlreadyExistErrorText: 'This user is already registered',
        linkSentAlertText: 'An email has been sent to the email address you provided to confirm your registration',
        recoveryLinkSentAlertText: 'An email has been sent to the specified email address to restore access',
        enter: 'Sign in',
        registry: 'Registration',
        recoveryAccess: 'Access recovery',
        password: 'Password',
        cancel: 'Cancel',
        iAgree: 'I accept the terms',
        agreementDocLinkName: 'User Agreement',
        and: 'and',
        privacyDocLinkName: 'Policies regarding the processing of personal data',
    },
    profile: {
        profile: 'Profile',
        exit: 'Sign out',
        name: 'First name',
        lastName: 'Last name',
        musicRelation: 'Relationship to music',
        musicRelations: {
            notListen: 'I don\'t listen to music',
            likeToListen: 'I love listening to music',
            studiedMusic: 'I studied music, but I haven\'t been doing it for a long time',
            amateurMusician: 'Amateur musician',
            professionalMusician: 'Professional stage musician',
            academicMusician: 'Professional academic musician',
        },
        birthDate: 'Date of Birth',
        country: 'Country',
        cancel: 'Cancel',
        save: 'Save'
    },
    restorePage: {
        passwordChangedSuccessfullyTitle: 'Password changed successfully!',
        passwordChangedSuccessfullyText: 'To continue as a registered user, log in to the site using your email address ' +
            'and password',
        linkNotFoundTitle: 'Link not found!',
        linkNotFoundText: 'The link is outdated or does not exist.',
        newPasswordForm: {
            title: 'Enter a new password:',
            password: 'Password',
            submit: 'Save new password',
        }
    }
}
