import React from "react";
import {useQuery} from "@apollo/client";
import {GET_TEST_TYPES} from "./TestList.const";
import {Box, Divider, Grid, Typography, Skeleton} from '@mui/material'
import {TestTypes, TestTypesVariables, TestTypes_testTypes_list_tests} from "../../queries";
import {useTranslation} from "react-i18next";
import {TestListItem} from "./TestListItem";
import {SizeType, useSizeType} from "../../utils/sizeType";


export const TestList = () => {
    const {i18n} = useTranslation();
    const {data, loading} = useQuery<TestTypes, TestTypesVariables>(
        GET_TEST_TYPES, {variables: {lang: i18n.language}}
    );
    const list = loading ? Array(6).fill({tests: Array(5).fill({})}) : data?.testTypes?.list;

    const sizeType = useSizeType();
    return (
        <>
            {list?.map((type, index) => (
                <Box sx={{width: '100%', bgcolor: 'background.paper'}} key={type?.id ?? index}>
                    <Box sx={{my: 3, mx: sizeType === SizeType.SMALL ? 0 : 2}}>
                        <Typography variant={sizeType === SizeType.SMALL ? "h5" : "h4"} gutterBottom>
                            { loading ?  <Skeleton width='60%' /> : type?.details?.[0]?.title }
                        </Typography>
                        <Grid container spacing={sizeType === SizeType.SMALL ? 3 : 5}>
                            {type?.tests?.map((test: TestTypes_testTypes_list_tests | null, i: number) => (
                                <Grid key={test?.id ?? i} item xs={12} sm={6} lg={4} xl={3} flexGrow={1}>
                                    {!loading ? (
                                        <TestListItem
                                            test={test}
                                        />
                                    ) : (
                                        <Box height={{xs: 86, md:182}}>
                                            <Skeleton variant="rounded" height='100%' />
                                        </Box>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {(index + 1) < list?.length && <Divider  variant="middle" />}
                </Box>
            ))}
        </>
    );
}
