import {useMutation, useQuery} from "@apollo/client";
import {
    GetTestById,
    GetTestById_tests_list,
    GetTestByIdVariables,
    NewStep,
    NewStep_test_sounds,
    NewStepVariables
} from "../../queries";
import {GET_TEST_BY_ID, NEW_STEP} from "./Test.const";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export type NextStep = (variables: {winner: string, looser: string}) => any
export type TestHook = (id: string) => {
    test: GetTestById_tests_list | null,
    loading: boolean,
    stepLoading: boolean,
    currentStepSounds: (NewStep_test_sounds | null)[] | null,
    percent: number,
    completed: boolean | null,
    userTestId: number | null,
    continueTest: () => unknown,
    restartTest: () => unknown,
    nextStep: NextStep,
}

export const useTest: TestHook = (id) => {
    const {i18n} = useTranslation();
    const {data, loading} = useQuery<GetTestById, GetTestByIdVariables>(GET_TEST_BY_ID, {
        variables: {
            id,
            lang: i18n.language
        }
    });
    const [nextStepMutate, {data: currentStep, loading: stepLoading}] = useMutation<NewStep, NewStepVariables>(NEW_STEP);
    const restartTest = () => {
        return nextStepMutate({variables: {testId: id, isNew: true}});
    };
    const continueTest = () => {
        return nextStepMutate({variables: {testId: id, isNew: false}});
    };
    const nextStep: NextStep = ({winner, looser}) => {
        return nextStepMutate({variables: {testId: id, winner, looser}});
    };
    const test = (data?.tests?.list || [])[0];
    let currentStepSounds = null;
    let userTestId: number | null = null;

    if (currentStep?.test?.sounds) {
        currentStepSounds = currentStep.test.sounds;
    }
    if (currentStep?.test?.userTestId) {
        userTestId = +currentStep.test.userTestId;
    }

    const percent = currentStep?.test?.percent ?? 0;
    const completed = test?.completionStatus ?? null;
    return {test, loading, stepLoading, currentStepSounds, userTestId, percent, completed, continueTest, restartTest, nextStep}
};

type  SoundsPlayerHook = (play: (num: 1|2) => void, currentStepSounds: any) => {
    play1: () => void,
    play2: () => void,
    stopAll: () => void,
    setSoundsRefsByIndex: (index: number) => any,
}

export const useSoundsPlayer: SoundsPlayerHook = (playCallback, currentStepSounds) => {
    const [sound1Loaded, setSound1Loaded] = useState(false);
    const [sound2Loaded, setSound2Loaded] = useState(false);
    const sound1Ref = useRef<HTMLAudioElement>();
    const sound2Ref = useRef<HTMLAudioElement>();
    const stop = () => {
        if(sound1Ref.current && sound1Loaded) {
            sound1Ref.current.pause();
            sound1Ref.current.currentTime = 0.0;
        }
        if(sound2Ref.current && sound2Loaded) {
            sound2Ref.current.pause();
            sound2Ref.current.currentTime = 0.0;
        }
    };

    const play1 = () => {
        stop();
        sound1Ref.current?.play().then(() => {
            setSound1Loaded(true);
        });
        playCallback(1);
    };

    const play2 = () => {
        stop();
        sound2Ref.current?.play().then(() => {
            setSound2Loaded(true);
        });
        playCallback(2);
    };

    useEffect(() => {
        setSound1Loaded(false);
        setSound2Loaded(false);
    }, [currentStepSounds]);

    const setSoundsRefsByIndex = (index: number): any => index ? sound2Ref : sound1Ref;
    return {play1, play2, setSoundsRefsByIndex, stopAll: stop }
};

type SelectSoundHook = (
    preSelect:() => void,
    soundsIds: [string, string],
    nextStep: NextStep
) => {
    selectSound1: () => void,
    selectSound2: () => void,
}

export const useSelectSound: SelectSoundHook = (preSelect, soundsIds, nextStep) => {
        const selectSound1 = () => {
            preSelect();
            nextStep({ winner: soundsIds[0], looser: soundsIds[1] });
        };

        const selectSound2 = () => {
            preSelect();
            nextStep({ winner: soundsIds[1], looser: soundsIds[0] });
        };

        return {selectSound1, selectSound2}
};
