import {AppBar, Box, FormControl, MenuItem, Select, Toolbar, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {Link} from "react-router-dom";
import React, {useCallback} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {useQuery} from "@apollo/client";
import {CHECK_AUTH_FOR_HEADER} from "./Header.const";
import {CheckAuthForHeader} from "../../queries";
import { Profile } from "../Profile/Profile";
import { Alert } from '@mui/material';
import {Login} from "../Login/Login";
import LanguageIcon from '@mui/icons-material/Language';
import {useTranslation} from "react-i18next";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

const useStyles = makeStyles(() =>
    createStyles({
        toolbar: {
            backgroundColor: '#3f51b5',
        },
        title: {
            color: 'white',
            textDecoration: 'none'
        },
        grow: {
            flexGrow: 1,
        },
    }),
);

const languages = ['ru', 'en', 'de', 'iw'];
const languageMap = Object.fromEntries(
    languages.map(l => [l, new Intl.DisplayNames([l], {type: "language"}).of(l)])
);

export const Header = () => {
    const {data} = useQuery<CheckAuthForHeader>(CHECK_AUTH_FOR_HEADER);
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const handleChange = useCallback((e: SelectChangeEvent) => {
        i18n.changeLanguage(e.target.value);
    }, [i18n]);
    return <>
        <AppBar position="sticky">
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" component={Link} to='/' className={classes.title} lineHeight={1.1}>
                    Comparator
                    <br/>
                    <span style={{
                        fontSize: "16px",
                        opacity: 0.9,
                    }}>
                        Pass any test and get a music recommendation
                    </span>
                </Typography>
                <div className={classes.grow}/>
                <Box display='flex'>
                    <Box display='flex' alignItems='center' textTransform='capitalize'>
                        <LanguageIcon/>
                        <FormControl sx={{ m: 1, color: 'white' }} size="small" variant='standard'>
                            <Select
                                sx={{ color: 'white' }}
                                id="demo-select-small"
                                value={i18n.language}
                                onChange={handleChange}
                            >
                                {languages.map((language) => (
                                    <MenuItem
                                        value={language}
                                        key={language}
                                    >
                                        <Box textTransform='capitalize'>{languageMap[language]}</Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {data?.isAuth ? (
                        <Profile />
                    ) : (
                        <Login />
                    )}
                </Box>
            </Toolbar>
        </AppBar>
        {!data?.isAuth && <Alert severity="warning">{t('mainAlert')}</Alert>}
    </>;
};
