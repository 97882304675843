import {ApolloClient, ApolloLink, createHttpLink, fromPromise, gql, InMemoryCache} from '@apollo/client';
import {onError} from "@apollo/link-error";
import {buildAxiosFetch} from "@lifeomic/axios-fetch";
import axios from 'axios';
import * as cacheConfig from './cacheConfig';
import * as resolvers from './resolvers';
import {typeDefs} from "./typeDefs.const";
import {storage} from "./localStorage";
import {NewToken} from "../queries";

const httpLink = createHttpLink({
    uri: (process.env.REACT_APP_API_ENDPOINT ?? '/') + (process.env.REACT_APP_GRAPHQL_PATH ?? 'graphql'),
    fetch: buildAxiosFetch(axios),
});

const errorLink: ApolloLink = onError(({
                               graphQLErrors, networkError, operation, forward
                           }) => {
    if (graphQLErrors) {
        for (const error of graphQLErrors) {
            const {message, locations, path} = error;
            if (message === 'Invalid Token') {
                const refreshToken = storage.token?.refreshToken?.token;
                return fromPromise(
                    getNewToken(refreshToken || '')
                ).filter(value => Boolean(value)).flatMap((token) => {
                    // retry the request, returning the new observable
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `Bearer ${token}`,
                        },
                    });
                    return forward(operation);
                });
            }
            console.log(`[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        }
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
}) as any;

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = storage.token?.accessToken;
    const authorizationHeader = token ? `Bearer ${token}` : null;
    operation.setContext({
        credentials: 'include',
        headers: {
            authorization: authorizationHeader,
        },
    });
    return forward(operation);
});

const cache = new InMemoryCache(cacheConfig);

export const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
        authMiddleware,
        errorLink,
        httpLink,
    ]),
    typeDefs,
    resolvers,
});

async function getNewToken(token: string): Promise<string | null> {
    try {
        const {data} = await client.mutate<NewToken>({
            mutation: gql`
                mutation NewToken($token: String!) {
                    useRefreshToken(token: $token) {
                        accessToken
                        refreshToken {
                            token
                            expiresAt
                        }
                    }
                }
            `,
            variables: {token}
        });
        storage.token = data?.useRefreshToken ?? null;
        return storage.token?.accessToken || '';
    } catch (e) {
        storage.token = null;
        await client.resetStore();
        return null;
    }
}
