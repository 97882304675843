import {Dictionary} from "./type";

export const de: Dictionary = {
    mainAlert: 'Um die Testergebnisse nicht zu verlieren, melden Sie sich bitte im System an',
    loginButton: 'Einloggen',
    back: 'Der Rücken',
    test: {
        purpose: 'Zweck:',
        description: 'Beschreibung:',
        startButton: 'Prüfung starten',
        listenSoundButton: 'hören',
        selectSoundButton: 'wählen',
        notFinishedTestText: 'Der Test ist bereits gelaufen, aber noch nicht abgeschlossen.',
        finishedTestText: 'Prüfung abgeschlossen!',
        continueTestButton: 'Weitermachen',
        selectOtherTestButton: 'Wählen Sie einen anderen Test',
        repeatTestButton: 'Von vorn anfangen',
        repeatTestButton2: 'Wiederholen Sie diesen Test',
        recommendsTitleText: 'Wir empfehlen zuzuhören:',
    },
    loginForm: {
        wrongPasswordErrorText: 'Login oder Passwort falsch',
        userAlreadyExistErrorText: 'Dieser Benutzer ist bereits registriert',
        linkSentAlertText: 'Eine E-Mail wurde an die von Ihnen angegebene E-Mail-Adresse gesendet, um Ihre Registrierung zu bestätigen',
        recoveryLinkSentAlertText: 'Eine E-Mail wurde an die angegebene E-Mail-Adresse gesendet, um den Zugriff wiederherzustellen',
        enter: 'Einloggen',
        registry: 'Anmeldung',
        recoveryAccess: 'Greifen Sie auf die Wiederherstellung zu',
        password: 'Passwort',
        cancel: 'Absagen',
        iAgree: 'Ich akzeptiere die Bedingungen',
        agreementDocLinkName: 'Nutzungsbedingungen',
        and: 'und',
        privacyDocLinkName: 'Richtlinien zur Verarbeitung personenbezogener Daten',
    },
    profile: {
        profile: 'Profil',
        exit: 'Ausloggen',
        name: 'Vorname',
        lastName: 'Familienname, Nachname',
        musicRelation: 'Beziehung zur Musik',
        musicRelations: {
            notListen: 'Ich höre keine Musik',
            likeToListen: 'Ich liebe es, Musik zu hören',
            studiedMusic: 'Ich habe Musik studiert, aber das mache ich schon lange nicht mehr',
            amateurMusician: 'Amateurmusiker',
            professionalMusician: 'Professioneller Bühnenmusiker',
            academicMusician: 'Professioneller akademischer Musiker',
        },
        birthDate: 'Geburtsdatum',
        country: 'Land',
        cancel: 'Absagen',
        save: 'Speichern'
    },
    restorePage: {
        passwordChangedSuccessfullyTitle: 'das Passwort wurde erfolgreich geändert!',
        passwordChangedSuccessfullyText: 'Um als registrierter Benutzer fortzufahren, melden Sie sich mit Ihrer ' +
            'E-Mail-Adresse auf der Website an und Passwort',
        linkNotFoundTitle: 'Verbindung nicht gefunden!',
        linkNotFoundText: 'Der Link ist veraltet oder existiert nicht.',
        newPasswordForm: {
            title: 'Geben Sie ein neues Kennwort ein:',
            password: 'Passwort',
            submit: 'Neues Passwort speichern',
        }
    }
}
