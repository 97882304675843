import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";

export function CompletedTestActions({restartTest}: { restartTest: () => void }) {
    const {t} = useTranslation();
    return <Box my={7}>
        <Grid container>
            <Grid item xs={12}>
                <Box mb={3}>
                    <Typography variant="h5" align="center">{t('test.finishedTestText')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="large"
                            onClick={restartTest}
                        >{t('test.repeatTestButton2')}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/"
                        >{t('test.selectOtherTestButton')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>;
}
