import {Button} from "@mui/material";
import React, {useState} from "react";
import {LoginForm} from "./LoginForm";
import {useTranslation} from "react-i18next";

export const Login = () => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return <>
        <Button color="inherit" onClick={handleClickOpen}>{t('loginButton')}</Button>
        <LoginForm open={open} onClose={handleClose}/>
    </>
};
