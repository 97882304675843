import {NextStep, useSelectSound, useSoundsPlayer} from "./Test.hooks";
import {NewStep_test_sounds} from "../../queries";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, ButtonGroup, Grid} from "@mui/material";
import {TestChartMemo} from "./TestChart";

export type StepActionsProps = {
    nextStep: NextStep,
    percent: number,
    loading: boolean,
    currentStepSounds: (NewStep_test_sounds | null)[] | null,
};

export const StepActions = ({currentStepSounds, loading, nextStep, percent}: StepActionsProps) => {
    const [canSelectSound, setSelectSound] = useState(false);
    const [showButtonsState, setShowButtonsState] = useState([false, false]);

    const showButtons = (index: number) => {
        if (index === 1) {
            setShowButtonsState([true, showButtonsState[1]])
        } else {
            setShowButtonsState([showButtonsState[0], true])
        }
    };
    useEffect(() => {
        setSelectSound(showButtonsState[0] && showButtonsState[1]);
    }, [showButtonsState]);

    useEffect(() => {
        setSelectSound(false);
        setShowButtonsState([false, false]);
    }, [currentStepSounds]);

    const ids: [string, string] = ['', ''];
    if (currentStepSounds && currentStepSounds[0]?.id) {
        ids[0] = currentStepSounds[0].id
    }
    if (currentStepSounds && currentStepSounds[1]?.id) {
        ids[1] = currentStepSounds[1].id
    }

    const {t} = useTranslation();
    const {play1, play2, setSoundsRefsByIndex, stopAll} = useSoundsPlayer(showButtons, currentStepSounds);
    const {selectSound1, selectSound2} = useSelectSound(stopAll, ids, nextStep);
    return <Box my={5}>
        <Box mb={3}>
            <TestChartMemo percent={percent} loading={loading}/>
        </Box>
        <Box>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item>
                    <ButtonGroup>
                        <Button disabled={!canSelectSound} onClick={selectSound1}>{t('test.selectSoundButton')}</Button>
                        <Button disabled={loading} onClick={play1}>{t('test.listenSoundButton')}</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item>
                    <ButtonGroup>
                        <Button disabled={loading} onClick={play2}>{t('test.listenSoundButton')}</Button>
                        <Button disabled={!canSelectSound} onClick={selectSound2}>{t('test.selectSoundButton')}</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {currentStepSounds?.map((step, index) => {
                const {id, path} = step ?? {id: index, path: null};
                const fullPath = (process.env.REACT_APP_API_ENDPOINT ?? '') + path?.slice(1);
                return (
                    <audio key={id} ref={setSoundsRefsByIndex(index)}>
                        <source src={`${fullPath}.mp3`} type='audio/mpeg'/>
                        <source src={`${fullPath}.ogg`} type='audio/ogg; codecs=vorbis'/>
                    </audio>
                )
            })}
        </Box>
    </Box>;
};
