import React, {FC, memo} from "react";
import {Box, styled, useTheme} from "@mui/material";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

import {SizeType, useSizeType} from "../../utils/sizeType";

const Chart = styled('div')({
    display: "flex",
    justifyContent: "center",
});
type TestChartProps = {
    percent: number,
    loading: boolean
}
const TestChart: FC<TestChartProps> = ({percent}: TestChartProps) => {
    const sizeType = useSizeType(500);
    const theme = useTheme();
    const isNormalSize = sizeType === SizeType.NORMAL;
    return (
        <Chart>
            <Box maxWidth={ isNormalSize ? 400 : 150} width='100%'>
                <CircularProgressbarWithChildren
                    value={percent}
                    styles={buildStyles({
                        strokeLinecap: 'round',
                        pathColor: theme.palette.primary.main,
                        trailColor: theme.palette.grey["300"],
                    })}
                >
                    <Box
                        fontSize={isNormalSize ? 45 : 21}
                        marginTop={'-10px'}
                        color={theme.palette.primary.main}
                    >
                        <strong>
                            {`${percent}%`}
                        </strong>
                    </Box>
                    <div/>
                </CircularProgressbarWithChildren>
            </Box>
        </Chart>
    );
}
export const TestChartMemo = memo(TestChart, (prevProps, newProps) => newProps.loading);
