import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent, TextField, DialogActions, Tabs, Tab, Snackbar, Checkbox
} from '@mui/material';

import {useMutation} from "@apollo/client";
import {LOGIN, REGISTRATION, SAVE_USER_HASH, SEND_LINK_FOR_RESTORE_ACCESS, useStyles} from "./Login.const";
import {
    Login,
    LoginVariables,
    Registration,
    RegistrationVariables, SendLinkForRestoreAccess,
    SendLinkForRestoreAccessVariables,
    Token
} from "../../queries";
import { Alert } from '@mui/material';
import {storage} from "../../core/localStorage";
import {useTranslation} from "react-i18next";

type LoginFormProps = { open: boolean, onClose: () => void };
export function LoginForm({open, onClose}: LoginFormProps) {
    const classes = useStyles();

    const [pendingOperation, setPendingOperation] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [accept, setAccept] = useState(false);
    const [error, setError] = useState<any>(null);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const variables = {email, password};

    const [saveUserHash, {client}] = useMutation(SAVE_USER_HASH);
    const [login] = useMutation<Login, LoginVariables>(LOGIN, {variables});
    const [registration] = useMutation<Registration, RegistrationVariables>(REGISTRATION, {variables});
    const [sendLinkForRestoreAccess] = useMutation<SendLinkForRestoreAccess, SendLinkForRestoreAccessVariables>(SEND_LINK_FOR_RESTORE_ACCESS, {variables: {
        email
    }});

    const auth = async (token?: Token | null) => {
        if(token) {
            storage.token = token;
            if(storage.userHash) {
                await saveUserHash();
            }
            client?.resetStore();
            onClose();
        }
    };

    const {t} = useTranslation();

    const onSubmit = async (event: any) => {
        setPendingOperation(true);
        event.preventDefault();
        if(!tabIndex) {
            const data = await login()
                .catch(() => {
                setError(t('loginForm.wrongPasswordErrorText'))
            });
            if(data) {
                await auth(data?.data?.loginWithEmail);
                onClose();
            }
        } else if (tabIndex === 1) {
            const result = await registration().catch(() => {
                setError(t('loginForm.userAlreadyExistErrorText'))
            });

            if(result) {
                alert(t('loginForm.linkSentAlertText'));
                onClose();
            }
        } else {
            if(email) {
                await sendLinkForRestoreAccess();
                alert(t('loginForm.recoveryLinkSentAlertText'));
                onClose();
            }
        }
        setPendingOperation(false);
    };

    const hasError = !!error;
    const errorHandleClose = () => {
      setError(null);
    };

    return (
        <>
            <Snackbar open={hasError} autoHideDuration={6000} onClose={errorHandleClose}>
                <Alert onClose={errorHandleClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <form onSubmit={onSubmit}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        centered
                        variant="fullWidth"
                        className={classes.loginTabs}
                    >
                        <Tab label={t('loginForm.enter')} />
                        <Tab label={t('loginForm.registry')} />
                        <Tab label={t('loginForm.recoveryAccess')} />
                    </Tabs>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />

                        {tabIndex !== 2 && (
                            <TextField
                                margin="dense"
                                label={t('loginForm.password')}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                            />
                        )}
                        {tabIndex === 1 && (
                            <div>
                                <Checkbox
                                    value={accept}
                                    onChange={(e) => setAccept(e.target.checked)}
                                />
                                <span>{t('loginForm.iAgree')} </span>
                                <a href="/agreement.doc">{t('loginForm.agreementDocLinkName')}</a>
                                <span> {t('loginForm.and')} </span>
                                <a href="/privacy.doc">{t('loginForm.privacyDocLinkName')}</a>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            {t('loginForm.cancel')}
                        </Button>
                        <Button type="submit" color="primary" disabled={(tabIndex === 1 && !accept) || pendingOperation}>
                            {!tabIndex ? t('loginForm.enter') : tabIndex === 1 ? t('loginForm.registry') : t('loginForm.recoveryAccess')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
