import {useMutation, useQuery} from "@apollo/client";
import {GET_PROFILE, LOGOUT, SAVE_PROFILE} from "./Profile.const";
import {GetProfile, SaveProfile, UserInput, Logout} from "../../queries";
import React, {ChangeEvent, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid, IconButton, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {SelectProps} from "@mui/material/Select/Select";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import * as fns from 'date-fns'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {storage} from "../../core/localStorage";
import {useTranslation} from "react-i18next";

export const Profile = () => {
    const {data, client} = useQuery<GetProfile>(GET_PROFILE);
    const [saveProfile] = useMutation<SaveProfile>(SAVE_PROFILE);
    const [logout] = useMutation<Logout>(LOGOUT);

    const [input, setInput] = useState<UserInput>({});
    const [open, setOpen] = useState(false);


    const {t} = useTranslation();
    const onClose = () => {
        setOpen(false)
    };
    const onOpen = () => {
        setOpen(true)
    };
    const onSubmit = async (e: any) => {
        e.preventDefault();
        await saveProfile({variables: {input}});
        onClose();
    };

    const textFieldProps = (name: keyof UserInput, value: string): TextFieldProps => ({
        fullWidth:true,
        name,
        value: input[name] ?? value,
        onChange: (e: ChangeEvent<HTMLInputElement>) => setInput({ ...input, [name]: e?.target?.value ?? '' })
    });

    const selectFieldProps = (name: keyof UserInput, value: string): SelectProps => ({
        name,
        value: input[name] ?? value,
        onChange: (e) => setInput({ ...input, [name]: e?.target?.value ?? '' })
    });

    const dateFieldProps = (name: keyof UserInput, defaultValue?: string | null): DatePickerProps<Date|null|undefined|string, Date> => {
        const format="dd.MM.yyyy";
        const value = input[name] ?? defaultValue;
        return({
            renderInput: (params) => <TextField {...params} name={name} />,
            inputFormat: format,
            value: value ? fns.parse(value, format, new Date()): value,
            onChange: (date) => setInput({ ...input, [name]: date && fns.format(date, format) })
        });
    };

    const exit = async () => {
        try {
            await logout();
        } catch (e) {
            console.warn('Не удалось очистить сессию на сервере: ', e)
        }
        storage.token = null;
        storage.userHash = null;
        await client.resetStore();
    };

    const {i18n} = useTranslation();
    const getCountryName = new Intl.DisplayNames([i18n.language], {type: 'region'});

    return <>
        <Button color="inherit" onClick={onOpen}>{data?.user?.firstName || t('profile.profile')}</Button>
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" >
            <form onSubmit={onSubmit}>
                <Grid container justifyContent="space-between">
                    <Grid item >
                        <DialogTitle>{t('profile.profile')}</DialogTitle>
                    </Grid>
                    <Grid item >
                        <Box p={2}>
                            <IconButton edge="end" size="small" color="secondary" onClick={exit}>
                                <span>{t('profile.exit')}&nbsp;</span><ExitToAppIcon fontSize="small"/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Box m={3}>
                        <Typography color="textSecondary" variant="body2">Email: {data?.user?.email}</Typography>
                    </Box>
                    <Box m={3}>
                        <TextField
                            label={t('profile.name')}
                            {...textFieldProps('firstName', data?.user?.firstName ?? '')}
                        />
                    </Box>
                    <Box m={3}>
                        <TextField
                            label={t('profile.lastName')}
                            {...textFieldProps('lastName', data?.user?.lastName ?? '')}
                        />
                    </Box>
                    <Box m={3}>
                        <FormControl fullWidth >
                            <InputLabel id="relation-select-label">{t('profile.musicRelation')}</InputLabel>
                            <Select
                                labelId="relation-select-label"
                                label={t('profile.musicRelation')}
                                {...selectFieldProps('musicRelation', data?.user?.musicRelation ?? '')}
                            >
                                <MenuItem value={'NOT_LISTEN'}>{t('profile.musicRelations.notListen')}</MenuItem>
                                <MenuItem value={'LIKE_TO_LISTEN'}>{t('profile.musicRelations.likeToListen')}</MenuItem>
                                <MenuItem value={'STUDIED_MUSIC'}>{t('profile.musicRelations.studiedMusic')}</MenuItem>
                                <MenuItem value={'AMATEUR_MUSICIAN'}>{t('profile.musicRelations.amateurMusician')}</MenuItem>
                                <MenuItem value={'PROFESSIONAL_MUSICIAN'}>{t('profile.musicRelations.professionalMusician')}</MenuItem>
                                <MenuItem value={'ACADEMIC_MUSICIAN'}>{t('profile.musicRelations.academicMusician')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box m={3} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                showToolbar={false}
                                label={t('profile.birthDate')}
                                {...dateFieldProps('birthday', data?.user?.data?.birthday)}
                                OpenPickerButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box m={3}>
                        <FormControl fullWidth>
                            <InputLabel id="country-select-label">{t('profile.country')}</InputLabel>
                            <Select
                                labelId="country-select-label"
                                label={t('profile.country')}
                                {...selectFieldProps('country', data?.user?.data?.country ?? '')}
                            >
                                {data?.countries?.map(country => country?.id && (
                                    <MenuItem value={country.id ?? ''} key={`country_${country.id}`}>{getCountryName.of(country.id)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Button onClick={onClose} color="primary">
                        {t('profile.cancel')}
                    </Button>
                    <Button type="submit" color="primary">
                        {t('profile.save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>;
};
